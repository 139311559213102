.#{$prefix}chat {


    @if(mixin-exists(hook-chat)) {@include hook-chat();}


}

.#{$prefix}chat-message {
    background: linear-gradient(to right bottom, var(--c-bg-4) 0%, var(--c-bg) 100%);
    border: 1px solid var(--c-gray-2);
    border-radius: var(--border-radius);
    padding: var(--margin-m);

    &.from-self {
        background: linear-gradient(to left bottom, var(--c-bg) 0%, var(--c-bg-4) 100%);
    }
    @if(mixin-exists(hook-chat-message)) {@include hook-chat-message();}
}

/* tooltips styles for popper based popup/tooltips */
