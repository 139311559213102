.#{$prefix}icon {
    height: 1.2em;
    width: 1.2em;
    flex: 1.2em;
    background-position: center center;
    -webkit-mask-size: contain;
    mask-size: contain;
    display: inline-block;
    transition: color 150ms ease;
    background-color: currentColor;

    // support specific icons by extending mask to src

    @if (mixin-exists(hook-icons)) {@include hook-icons()}
}
