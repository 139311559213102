/**
 * Order is very important.
 * 1st: SCSS variable overrides
 * 2nd: build import
 * 3rd: component hooks
 * 4th: component import
 * 5th: CSS variables declaration/override (build includes :root level declaration)
 * 6th: other custom CSS
 */

// font
//@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Varela%20Round:wght@200;300;400;500;600;700;800&display=swap');

// alternative font: alef
//@import url('https://fonts.googleapis.com/css2?family=Alef:wght@200;300;400;500;600;700;800&display=swap');

//@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

// he-IL - rubik
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

// en-US - public sanse
//@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// alef
//@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap');
// 1st: SCSS variable overrides
$global-prefix: "";

// 2nd: build import
@import "../../../../../../otherSrc/client/we/scss/build";

// our custom component // * NOTE: we can not include partial SCSS files inside the client forlder, atm.

// 3rd: component hooks

@mixin hook-button {
  border-radius: var(--border-radius);
  &--facebook {
    @include _button-color(
      var(--c-facebook),
      var(--c-bg),
      var(--c-bg),
      var(--c-facebook),
      true
    );
    border: 2px solid var(--c-facebook);
  }

  &--alt-inverse  {
    color: var(--c-gray-5);
  }
}

@mixin hook-form-field-element {
  background-color: var(--c-bg);
  border-radius: var(--border-radius);
}

@mixin hook-form-form-control {
  border-radius: calc(var(--border-radius) * 0.75);
}

@mixin hook-field {
  border-radius: calc(var(--border-radius) * 0.75);

  input {
    border-radius: calc(var(--border-radius) * 0.75);
    background-color: var(--c-bg);
  }
}

@mixin hook-form-field-error-message {
  margin-top: calc(var(--margin-s) * 0.75);
  padding-inline-start: 10px;
}

@mixin hook-modal {
    &__inner {
        border-radius: var(--border-radius-m);
    }
}

@mixin hook-alert {
    a:not(button) {
        // color: var(--c-bg-1);
        //text-decoration: underline;
    }
}

@mixin hook-panel {
  border-radius: calc(var(--border-radius) * 0.75);
}

@mixin hook-form-field-placeholder {
  opacity: 0.6;
  color: var(--c-gray-5);
}

@mixin hook-notification {
  //background-color: var(--c-bg-2);
}

@mixin hook-tabs {
  &__control {
   // color: var(--c-inverse);

    &.active {
     // color: var(--c-lead);
    }

    &.active:hover {
    //  color: var(--c-lead);
    }

    &:not(.active):hover {
    //  color: var(--c-inverse);
    }

  }
}

@mixin hook-pagination {
  gap: var(--margin-m);
}

.pagination li.pagination-icon a, .pagination li.pagination-icon span {
  border-color: transparent;
}

@mixin hook-pagination-link {
  border-radius: 50%;
  border: 2px solid var(--c-alt);
  font-weight: 600;
  font-size: var(--font-l);
  color: var(--c-text);
  height:2em;
  width: 2em;
  padding:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--c-alt);
}

@mixin hook-pagination-clickable-link {
  color: var(--c-text)!important;
  background: var(--c-bg-1);
}
// 4th: component import
@import "../../../../../../otherSrc/client/we/scss/components";

// 5th: CSS variables declaration/override
:root {
  --c-core-lead-1: #8EB1C7;
  --c-core-lead-2: #82AEB1;
  --c-core-lead: #1A535C;
  --c-core-lead-4: #1a535c;
  --c-core-lead-5: #0c262a;

  --c-lead-1: #8EB1C7;
  --c-lead-2: #82AEB1;
  --c-lead: #1A535C;
  --c-lead-4: #1a535c;
  --c-lead-5: #0c262a;

  --c-core-alt-1: #EFE2CF;
  --c-core-alt-2: #DAC3A8; // ;#75C594;
  --c-core-alt:   #E3A864; // ;#5CAC7B; // this is darker than the design
  --c-core-alt-4: #d98a2e; // ;#5CAC7B; // this is darker than the design
  --c-core-alt-5: #a9691f;

  --c-alt-1: #EFE2CF;
  --c-alt-2: #DAC3A8; // ;#75C594;
  --c-alt:   #E3A864; // ;#5CAC7B; // this is darker than the design
  --c-alt-4: #d98a2e; // ;#5CAC7B; // this is darker than the design
  --c-alt-5: #a9691f;

  --c-core-bg-1: #FFFEFF;
  --c-core-bg-2: #fffbf9;
  --c-core-bg:   #fffaf7;
  --c-core-bg-4: #F6F0E6;
  --c-core-bg-5: #F9F6F0;

  --c-core-inverse: #000000;
  --c-text: #000000;

  --c-gray-1: #ECECEB;
  --c-gray-2: #d3d3d3;
  --c-gray: #c6c5c4;
  --c-gray-4: #5F5F5F;
  --c-gray-5: #2c2c2c;

  --border-radius: 20px;

  font-size: var(--font-m);
}

// 6th: other custom CSS
*,
*::before,
*::after {
  box-sizing: border-box!important;
}

// 6th: custom css
html,
body {
  background: var(--c-bg);
//"Manrope"
  //"'Public Sans'
  //"Comfortaa"
  * {
    font-family: "Public Sans", "Assistant", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  &[lang="he"] * {
    font-family: "Rubik", "Assistant", -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
}
