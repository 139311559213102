.#{$prefix}tooltip {
    position: relative;
    background: var(--c-bg);
    z-index: var(--z-top-1);

    &:not(.active) {
        pointer-events: none;
    }

    [role="tooltip-content"] {
        background: var(--c-bg);
        padding: var(--margin-s) var(--margin-m);
        border-radius: var(--border-radius-sharp);
        box-shadow: var(--box-shadow-2);
        color: var(--c-inverse);
        font-size: var(--font-m);
        border: 1px solid var(--c-gray-2);
        min-width: 10em;

        @include whenSmallerThan(s) {
            min-width: 5em;
        }
    }

    [role="tooltip-arrow"],
    [role="tooltip-arrow"]::before {
        position: absolute;
        width: 10px;
        height: 10px;
        z-index: -1;
        background-color: var(--c-gray-2);
        border: 1px solid var(--c-gray-2);

    }

    [role="tooltip-arrow"] {
        visibility: hidden;
    }

    [role="tooltip-arrow"]::before {
        visibility: visible;
        content: '';
        transform: rotate(45deg);
    }

    // arrow position modifiers
    &[data-popper-placement^='top'] > [role="tooltip-arrow"] {
        bottom: -5px;
    }

    &[data-popper-placement^='bottom'] > [role="tooltip-arrow"] {
        top: -5px;
    }

    &[data-popper-placement^='left'] > [role="tooltip-arrow"] {
        right: -5px;
    }

    &[data-popper-placement^='right'] > [role="tooltip-arrow"] {
        left: -5px;
    }

    // themes
    &--gray-5 {
        [role="tooltip-content"] {
            background: var(--c-gray-5);
            color: var(--c-bg);
            border: 1px solid var(--c-gray-5);
        }

        [role="tooltip-arrow"],
        [role="tooltip-arrow"]::before {
            background-color: var(--c-gray-5);
            border: 1px solid var(--c-gray-5);
        }
    }

    &--danger, &--error {
        [role="tooltip-content"] {
            background: var(--c-bg);
            color: var(--c-danger);
            border: 1px solid var(--c-danger);
        }

        [role="tooltip-arrow"],
        [role="tooltip-arrow"]::before {
            background-color: var(--c-danger);
            border: 1px solid var(--c-danger);
        }
    }

    &--success {
        [role="tooltip-content"] {
            background: var(--c-bg);
            color: var(--c-success);
            border: 1px solid var(--c-success);
        }

        [role="tooltip-arrow"],
        [role="tooltip-arrow"]::before {
            background-color: var(--c-success);
            border: 1px solid var(--c-success);
        }
    }
}

/* tooltips styles for popper based popup/tooltips */
