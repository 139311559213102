@use "sass:map";
@import "variables";



@function mapGetWithDefault($map, $key, $default) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    } @else {
        @return $default;
    }
}

@function enforceMap($input) {
    @if ($input == false) {
        @return ();
    }
    // plain
    @if type-of($input) != map and type-of($input) != list {
        @return (0:$input);
    }

    // list
    @if type-of($input) == list {
        $convertedMap: ();
        @each $value in $input {
            $convertedMap: map-merge($convertedMap, ($value: $value));
        }
        @return $convertedMap;
    }
    // map
    @return $input;
}

@function isPropertyValue($value) {
    @if type-of($value) == number {
        @return true;
    }

    @if type-of($value) == string and str-index($value, ":") == null {
        @return true; // It is a property value
    } @else {
        @return false; // It is a CSS string
    }
}

@mixin declareBasicRule ($prop, $value,$isImportant: false) {
        @if ($isImportant) {
            #{$prop}: $value !important;
        } @else {
            #{$prop}: $value;
        }

}

@mixin declareBasicRules($rules) {
    @if ($rules != false) {
        @each $propName, $value in $rules {
            #{$propName}: $value;
        }
    }
}
@mixin declareRuleWithSelector($selectorClass,$prop,$value,$isImportant : false, $fixedRules: false) {

    .#{$global-prefix}#{$selectorClass} {
        // case of prop: value
        @if(isPropertyValue($value)) {
            @include declareBasicRule($prop,$value,$isImportant);
            @include declareBasicRules($fixedRules);
        } @else { // case of key: prop-value map
            @each $propName, $propValue in $value {
                @include declareBasicRule($propName,$propValue,$isImportant);
                @include declareBasicRules($fixedRules);
            }
        }

    }
}

@mixin declareUtility($conf) {
    $className:mapGetWithDefault($conf, "className", "");
    $prop: mapGetWithDefault($conf, "property",$className);
    $important: mapGetWithDefault($conf, "important",false);
    $responsive: mapGetWithDefault($conf, "responsive",false);
    $states: mapGetWithDefault($conf, "states",false);
    $classPrefix: mapGetWithDefault($conf, "classPrefix",false);
    $classSuffix: mapGetWithDefault($conf, "classSuffix",false);
    $values : mapGetWithDefault($conf, "values",false);
    $fixedRules : mapGetWithDefault($conf, "fixedRules",false);
    $invertedValueKeyOrder: mapGetWithDefault($conf, "invertedValueKeyOrder",false);
    $valuePrefix : mapGetWithDefault($conf, "valuePrefix",false);
    $valueSuffix : mapGetWithDefault($conf, "valueSuffix",false);
    $mobileFirstResponsive: mapGetWithDefault($conf, "mobileFirstResponsive",true);

    // support list
    @if type-of($values) == list {
        $convertedMap: ();
        @each $value in $values {
            $convertedMap: map-merge($convertedMap, ($value: $value));
        }
        $values: $convertedMap;
    }

    @each $valueKey, $valueVal in $values {
        // support classPrefix
        $selector : '';
        // build class name. Account for empty operator
        @if (  $valueKey and $valueKey != '') {
            $selector:#{$className}-#{$valueKey};
        } @else {
            $selector:#{$className}#{$valueKey};
        }

        @if($classPrefix) {
            $selector: #{$classPrefix}#{$selector}
        }

        @if($classSuffix) {
            $selector:#{$selector}#{$classSuffix}
        }

        @if ($valuePrefix  and $valuePrefix != "") {
            $valueVal: #{$valuePrefix}#{$valueVal};
        }

        @if ($valueSuffix  and $valueSuffix != "") {
            $valueVal: #{$valueVal}#{$valueSuffix};
        }

        // handle case of values like so for flex (row : "flex-direction: row; display: flex"
        @include declareRuleWithSelector($selector, $prop,$valueVal,$important,$fixedRules);

        @each $key, $state in enforceMap($states) {
            $stateSelector: #{$state}+'\\:'+#{$selector}+'\:'+#{$state};
            @include declareRuleWithSelector($stateSelector,$prop,$valueVal,$important,$fixedRules);

        }

    }

}

@mixin declareUtilities($utilities) {
    @each $className, $conf in $utilities {
        $finalClassName : mapGetWithDefault($conf, "className",$className);
        $property: mapGetWithDefault($conf, "property",$className);
        $important: mapGetWithDefault($conf, "important",false);
        $responsive: mapGetWithDefault($conf, "responsive",false);
        $state: mapGetWithDefault($conf, "state",false);
        $values:mapGetWithDefault($conf, "values",());
        $classPrefix:mapGetWithDefault($conf, "classPrefix",false);
        $classSuffix:mapGetWithDefault($conf, "classSuffix",false);
        $states: mapGetWithDefault($conf, "states",false);
        $fixedRules: mapGetWithDefault($conf, "fixedRules",false);
        $invertedValueKeyOrder: mapGetWithDefault($conf, "invertedValueKeyOrder",false);
        $invertedValueKeyOrder: mapGetWithDefault($conf, "invertedValueKeyOrder",false);
        $valuePrefix:mapGetWithDefault($conf, "valuePrefix",false);
        $valueSuffix:mapGetWithDefault($conf, "valueSuffix",false);
        $mobileFirstResponsive:mapGetWithDefault($conf, "mobileFirstResponsive",true);
        $utilityConf : (
                className: $finalClassName,
                property : $property,
                important :$important,
                responsive: $responsive,
                state: $state,
                values:$values,
                classPrefix: $classPrefix,
                classSuffix: $classSuffix,
                states: $states,
                fixedRules: $fixedRules,
                invertedValueKeyOrder: $invertedValueKeyOrder,
                valuePrefix:$valuePrefix,
                valueSuffix:$valueSuffix,
                mobileFirstResponsive:$mobileFirstResponsive
        );

        @include declareUtility($utilityConf);

        @if ($responsive and $mobileFirstResponsive) {
            @each $prefix,$breakPointValue in $break-points-map-readable {
                @media (min-width: #{$breakPointValue}) {
                    $prefixedClassName: #{$prefix}\:#{$finalClassName};
                    $responsiveConf: map-merge($utilityConf, (className: $prefixedClassName));
                    @include declareUtility($responsiveConf);
                }
            }
        }

        @if ($responsive and not $mobileFirstResponsive) {
            @each $prefix,$breakPointValue in $break-points-map-readable {
                @media (max-width: #{$breakPointValue}) {
                    $prefixedClassName: #{$prefix}\:#{$finalClassName};
                    $responsiveConf: map-merge($utilityConf, (className: $prefixedClassName));
                    @include declareUtility($responsiveConf);
                }
            }
        }
    }
}


