
.#{$prefix}breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-inline-start: 0;
    margin: 0;
    font-size: var(--font-s);


    &--large, &--l {
        font-size: var(--font-m);

        li {
            margin-inline-end: var(--margin-l);
            &::after {
                margin-inline-start: var(--margin-m);
            }

            &:last-of-type {
                margin-inline-end: 0;
            }

            &:last-of-type::after {
                margin-inline-end: 0;
            }
        }
    }
    li {
        position: relative;
        margin-inline-end: var(--margin-m);

        &::after {
            content : ' / ';
            margin-inline-start: var(--margin-s);
            letter-spacing: 0;
        }

        &:last-of-type {
            margin-inline-end: 0;
        }


        &:last-of-type::after {
            display: none;
            margin-inline-end: 0;
        }

        a {

            color: var(--c-gray-4);

            &:hover {
                text-decoration: underline;
            }

        }

        span {

        }
    }
}
