$paginationSelector : '.#{$prefix}pagination';

#{$paginationSelector} {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: var(--margin-s);
    margin: 0;
    padding: 0;

    @media screen and (max-width: 400px) {
        gap: var(--margin-xs);
    }

    li {
        display: flex;
        align-items: stretch;
    }

    a, span {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--margin-s) var(--margin-m);
        border: 1px solid var(--c-gray-2);
        @include transition ();

        @media screen and (max-width: 400px) {
            padding: var(--margin-s) var(--margin-m);
        }

        @if (mixin-exists(hook-pagination-link)) {
            @include hook-pagination-link();
        }
    }

    a {
        color: var(--c-lead);

        &:hover {
            background: var(--c-bg-4);
            text-decoration: none;
        }

        @if (mixin-exists(hook-pagination-clickable-link)) {
            @include hook-pagination-clickable-link();
        }
    }

    @if (mixin-exists(hook-pagination)) {
        @include hook-pagination();
    }

}

#{$paginationSelector}-totals {
    text-align: center;
    color: var(--c-gray-4);
    margin-top: var(--margin-m);
    font-size: var(--font-s);
}
