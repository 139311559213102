$dropdownSelector : '.#{$prefix}dropdown';


#{$dropdownSelector} {
    padding: var(--margin-m);
    background-color: var(--c-bg);
    border: 1px solid var(--c-gray-1);
    box-shadow: var(--box-shadow-2);
    z-index: var(--z-top-1);
    width: 100%;
    flex: 100%;
    border-radius: var(--border-radius-s);

    @include whenSmallerThan(m) {
        padding: var(--margin-m);
    }

    @if(mixin-exists(hook-dropdown)) {@include hook-dropdown();}


}
