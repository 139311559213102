@use 'sass:map';
@mixin prefix($property, $value) {
    -webkit-#{$property} : #{$value};
    -moz-#{$property}    : #{$value};
    -o-#{$property}      : #{$value};
    #{$property}         : #{$value};
}

@mixin vendorPrefix($property, $value) {
    @include prefix($property, $value);
}

@mixin transition($property: all, $duration: 150ms, $function: ease, $delay: 0ms) {
    @include prefix(transition, $property $duration $function $delay);
}

// responsive mixins
@mixin mediaSmall {
    @media (max-width : #{$screen-s-max}) {
        @content;
    }
}

@mixin mediaLargerThanSmall {
    @media (min-width : #{$screen-s-min}) {
        @content;
    }
}

@mixin mediaMedium {
    @media (max-width : #{$screen-m-max}) {
        @content;
    }
}

@mixin mediaLargerThanMedium {
    @media (min-width : #{$screen-m-min}) {
        @content;
    }
}

@mixin mediaLarge {
    @media (max-width : #{$screen-l-max}) {
        @content;
    }
}

@mixin mediaLargerThanLarge {
    @media (min-width : #{$screen-l-min}) {
        @content;
    }
}

@mixin mediaXlarge {
    @media (max-width : #{$screen-xl-max}) {
        @content;
    }
}

@mixin mediaLargerThanXLarge {
    @media (min-width : #{$screen-xl-min}) {
        @content;
    }
}

@mixin whenMaxWidth($param) {
    @media (max-width : #{$param}) {
        @content;
    }
}


@mixin whenSmallerThan($param) {
    @if (map-has-key($break-points-map-readable, $param)) {
        @media (max-width : map.get($break-points-map-readable, $param)) {
            @content;
        }
    } @else {
        @media (max-width : #{$param}) {
            @content;
        }
    }
}

@mixin whenLargerThan($param) {
    @if (map-has-key($break-points-map-readable, $param)) {
        @media (min-width : map.get($break-points-map-readable, $param)) {
            @content;
        }
    } @else {
        @media (min-width : #{$param}) {
            @content;
        }
    }
}

@mixin whenMediaBelow($param) {
    @media (max-width : #{$param}) {
        @content;
    }
}

@mixin whenMinWidth($param) {
    @media (min-width : #{$param}) {
        @content;
    }
}

@mixin whenMediaAbove($param) {
    @media (min-width : #{$param}) {
        @content;
    }
}


@mixin centerBG {
    background-size     : auto auto;
    background-position : center center;
    background-repeat   : no-repeat;
}

@mixin coverBG {
    background-size     : cover;
    background-position : center center;
    background-repeat   : no-repeat;
}

@mixin square($size) {
    height : #{$size};
    width  : #{$size};
    flex   : 0 0 #{$size};
}

@mixin circle($size) {
    @include square($size);
    border-radius : 50%;
}
