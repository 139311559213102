.#{$prefix}media-manager, .#{$prefix}mm {
    border: 1px solid var(--c-gray-2);
    box-shadow: var(--box-shadow-2);

    &__toolbar-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__toolbar{
        padding: var(--margin-m) var(--margin-l);
        background: var(--c-gray-1);
        border-bottom: 1px solid var(--c-gray-2);
        box-shadow: var(--box-shadow-2);

    }

    &__items {
        padding: var(--margin-xl) var(--margin-l);
    }


    &__footer-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
    }

    &__footer {
        padding: var(--margin-m) var(--margin-l);
        background: var(--c-gray-1);
        border-top: 1px solid var(--c-gray-2);
        box-shadow: var(--box-shadow-2);
    }
}

.#{$prefix}media-manager-item, .#{$prefix}mm-item{
    position: relative;
    flex: 0 0 150px;
    max-width: 150px;
    height: 150px;
    border: 1px solid var(--c-gray-2);
    box-shadow: var(--box-shadow-2);
    z-index: 1;
    border-radius: var(--border-radius);

    &.current {
        border: 2px solid var(--c-gray);
        box-shadow: var(--box-shadow-4);
    }

    &:hover {
        z-index: 3;
    }


    &__preview {
        border-bottom: 2px solid var(--c-gray-2);
        width: 100%;
        height: 100px;
        border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &__preview-inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: auto 100%;
        background-position: center center;
        background-color: var(--c-gray-1);
        background-repeat: no-repeat;
        border-radius:  var(--border-radius) var(--border-radius) 0 0;
    }

    &__meta {
        padding: var(--margin-s);
        display: flex;
        gap: var(--margin-s);
        align-items: center;
        justify-content: center;
        background-color: var(--c-bg);
        border-radius: 0 0 var(--border-radius) var(--border-radius);

        &:hover {
            .mm-item__name {
                overflow: visible;
                border: 1px solid var(--c-gray);
                box-shadow: var(--box-shadow-1);
            }
        }
    }

    &__name {
        padding: var(--margin-xs);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        background-color: var(--c-bg);
    }
}


