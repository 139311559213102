$tableSelector : '.#{$prefix}table';

#{$tableSelector}{
    --table-head-bg : transparent;
    --table-head-color : var(--c-gray-4);
    --table-width-s: 150px;
    --table-width-m: 250px;
    --table-width-l: 350px;

    width: 100%;
    max-width: 100%;
    overflow-y: auto;
    overflow-x: auto;
    //display: block;
    border-collapse: collapse;
    border: 1px solid transparent;
    padding-bottom: 1px; // fix for TR border overflow


    // align variations can be set in any element in the table (including the Table element itself)
    // effect is recursive
    &#{$tableSelector}--middle, *#{$tableSelector}--middle {
        * {
            vertical-align: middle;
        }


    }

    &#{$tableSelector}--top, *#{$tableSelector}--top {
        vertical-align: top;

        * {
            vertical-align: top;
        }
    }

    &#{$tableSelector}--bottom, *#{$tableSelector}--bottom {
        vertical-align: bottom;
        * {
            vertical-align: bottom;
        }
    }


    &#{$tableSelector}--inline-start, *#{$tableSelector}--inline-start {
        text-align: start;
        * {
            text-align: start;
        }
    }

    &#{$tableSelector}--inline-end, *#{$tableSelector}--inline-end {
        text-align: end;
        * {
            text-align: end;
        }
    }

    &#{$tableSelector}--center, *#{$tableSelector}--center {
        text-align: center;
        * {
            text-align: center;
        }
    }

    // col sizes
    &#{$tableSelector}--truncate, *#{$tableSelector}--truncate {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    td#{$tableSelector}--shrink, th#{$tableSelector}--shrink {
        width: 1px;
        min-width: 1px;
    }

    td#{$tableSelector}--grow, th#{$tableSelector}--grow,
    td#{$tableSelector}--expand, th#{$tableSelector}--expand {
        width: 100%;
    }

    td.table--small, th.table--small,
    td.table--s, th.table--s {
        width: var(--table-width-s);
        max-width: var(--table-width-s);
    }

    td.table--medium, th.table--medium,
    td.table--m, th.table--m {
        width: var(--table-width-m);
        max-width: var(--table-width-m);
    }

    td.table--large, th.table--large,
    td.table--l, th.table--l {
        width: var(--table-width-s);
        max-width: var(--table-width-s);
    }

     tr {
         border: none;
         position: relative;
         margin-bottom: 1px;
         width: 100%;

         &:after {
             content: ' ';
             height: 0;
             position: absolute;
             bottom: 0px;
             left: 0;
             width: 100%;
             border-bottom: 1px solid var(--c-gray-2);
         }

         &:nth-child(2n+2) {
             background: var(--c-gray-1);
         }
     }

     thead tr {
         background-color: var(--table-head-bg);
         color: var(--table-head-color);
     }

     th,td {
         border: 0 solid transparent;
         border-collapse: collapse;
         padding: 0.8em;
         min-width: 50px;

     }

     th {
        text-align: start;
         font-weight: 600;
         font-size: var(--font-m);
         text-transform: uppercase;
     }

     td {
         word-break: break-word;
     }

     // variations
     &--tight,&--s, &--small {
         th,td {
             padding: var(--margin-s);
         }
     }

     &--loose, &--l, &--large {
         th {
             padding: var(--margin-m) var(--margin-l);
         }

         td {
             padding: var(--margin-l);
         }
     }

     &--highlight {

         tr {
             @include transition(box-shadow, 150ms, ease-out);
         }

         tr:hover {
             background-color: var(--c-gray-2);
             box-shadow: 0 0 10px 0 rgba(0,0,0,0.15) inset ;
             z-index: 2;
         }

         thead tr:hover {
             background-color: var(--table-head-bg);
             box-shadow: none;
         }
     }

     &--no-strips {
         tr:nth-child(2n+2) {
             background: transparent;
         }
     }

    &--justify {
        tr {
            td:first-of-type, th:first-of-type {
                padding-inline-start: 0;
                margin-inline-start: 0;
            }

            td:last-of-type, th:last-of-type {
                padding-inline-end: 0;
                margin-inline-end: 0;
            }
        }
    }
    @if(mixin-exists(hook-table)) {@include hook-table();}
}
