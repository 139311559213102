$cardPrefix : '.#{$prefix}card';

#{$cardPrefix} {
    --card-title-font-size: var(--font-2xl);
    --card-header-margin: var(--margin-xl);

    position: relative;
    padding:  var(--margin-xl) var(--margin-xl) 0 var(--margin-xl) ;
    background: var(--c-bg);
    box-shadow: var(--box-shadow-2);
    border-radius: var(--border-radius-sharp);
    border: 1px solid var(--c-gray-1);

    &__header {
        margin-bottom: var(--card-header-margin);

        .#{$prefix}badge {
            display: inline-block;
            margin-inline-start: auto;
            margin-bottom: var(--margin-s);
        }
    }

    &__title {
        display: flex;
        align-items: flex-start;
        width: 100%;
        gap: var(--margin-xs);
        font-size: var(--card-title-font-size);
        margin-bottom: var(--margin-xs);


    }

    &__sub-title {
        display: flex;
        align-items: flex-start;
        width: 100%;
        font-size: var(--font-l);
        font-weight: 300;
        gap: var(--margin-m);
    }

    &__body {
        margin-bottom: var(--margin-m)
    }

    &__body:last-child { // padding bottom in case theres no footer
        padding-bottom: var(--margin-xl);
    }

    &--hover {
        cursor: pointer;
        @include transition ('box-shadow', 300ms);

        &:hover {
            box-shadow: var(--box-shadow-5);
        }
    }

    &__footer {
        border-top: 1px solid var(--c-gray-4);
        margin-top: var(--margin-xl);
        padding-top: var(--margin-l);
        padding-bottom: var(--margin-l);

        @include whenSmallerThan(m) {
            margin-top: var(--margin-l);
            padding-top: var(--margin-m);
        }
    }

    &--tight {
        padding: calc(var(--margin-l));
        box-shadow: var(--box-shadow-1);

        #{$cardPrefix}__header {
            margin-bottom: var(--margin-l);
        }

        #{$cardPrefix}__title {
            font-size: var(--font-xl);
            margin-bottom: 0;

        }

        #{$cardPrefix}__sub-title {
            font-size: var(--font-m);
            font-weight: 300;
        }

        #{$cardPrefix}__body, #{$cardPrefix}__body p {
            font-size: calc(var(--font-m) * 0.9);
        }

        #{$prefix}card__footer {
            margin-top: var(--margin-l);
            padding-top: var(--margin-m);

            @include whenSmallerThan(m) {
                margin-top: var(--margin-l);
                padding-top: var(--margin-m);
            }
        }
    }

    &--inverse {
        color: var(--c-bg);
        background-color: var(--c-inverse);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-lead-2);
        }

        #{$prefix}card__footer {
            border-top-color: var(--c-bg);
        }
    }

    &--gray-1-gradiant {
        background: linear-gradient(-45deg, var(--c-gray-1) 0%, var(--c-bg) 100%);

        [dir=ltr] & {
            background: linear-gradient(45deg, var(--c-gray-1) 0%, var(--c-bg) 100%);
        }

        border-color: var(--c-gray-2);

        a:not(button) {
            color: var(--c-lead);
        }

        #{$prefix}card__footer {
            border-top-color: var(--c-gray-2);
        }
    }

    &--gray-1 {
        background-color: var(--c-gray-1);
        border-color: var(--c-gray-2);

        a:not(button) {
            color: var(--c-lead);
        }

        #{$prefix}card__footer {
            border-top-color: var(--c-gray-2);
        }
    }

    &--lead {
        color: var(--c-bg);
        background-color: var(--c-lead);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-bg);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        #{$prefix}card__footer {
            border-top-color: var(--c-bg);
        }
    }

    &--alt {
        color: var(--c-bg);
        background-color: var(--c-alt);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-bg);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }

        #{$prefix}card__footer {
            border-top-color: var(--c-bg);
        }
    }

    &--success {
        color: var(--c-inverse);
        background-color: var(--c-bg);
        border-color: var(--c-success);

        .card__header {
            color: var(--c-success);
        }

        a:not(button) {
            color: var(--c-inverse);
        }

        .#{$prefix}card__footer {
            border-top-color: var(--c-success);
        }
    }

    &--error, &--danger {
        color: var(--c-inverse);
        background-color: var(--c-bg);
        border-color: var(--c-danger);

        .card__header {
            color: var(--c-danger);
        }

        a:not(button) {
            color: var(--c-inverse);
        }

        .#{$prefix}card__footer {
            border-top-color: var(--c-danger);
        }
    }
    @if(mixin-exists(hook-card)) {@include hook-card();}

}
