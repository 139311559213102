
// general typography


.#{$prefix}text-bold {
    font-weight: bold!important;
}

.#{$prefix}text-extra-bold, .#{$prefix}text-xbold {
    font-weight: bolder!important;
}

.#{$prefix}text-normal {
    font-weight: 400!important;
}

.#{$prefix}text-light {
    font-weight: lighter!important;
}
