//circle

.#{$prefix}side-nav {
    display: inline-block;
    width: 100%;
    height: 100%;
    max-width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-y: auto;
    overflow-x: visible!important;

    &--mode-icon {
        width: auto;
    }

    @if(mixin-exists(side-nav)) {@include side-nav();}


    &.#{$prefix}item {
        overflow-x: visible;
        @if(mixin-exists(side-nav-item)) {@include side-nav-item();}
    }

    .#{$prefix}side-nav-item {
        position: relative;
        overflow-x: visible;
        a, > span {
            @include transition();
            font-size: var(--text-m);
            color: var(--c-gray-5);
            padding-inline: calc(var(--margin-m));
            padding-block: calc(var(--margin-m) - 2px);
            margin-bottom: 3px;
            border-radius: var(--border-radius-s);
            background: transparent;


            &.active{
                background: var(--c-gray-1);
                &:before {
                    content: ' ';
                    position: absolute;
                    height: 100%;
                    inset-inline-start: 0;
                    top: 0;
                    width: 4px;
                    background: var(--c-gray);
                    border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
                }
            }
            &.router-link-exact-active,{
                background: var(--c-gray-2);
                &:before {
                    content: ' ';
                    position: absolute;
                    height: 100%;
                    inset-inline-start: 0;
                    top: 0;
                    width: 4px;
                    background: var(--c-lead);
                    border-radius: var(--border-radius-s) 0 0 var(--border-radius-s);
                }
            }
            &:hover {
                background: var(--c-gray-5);
                text-decoration: none;
                color: var(--c-bg);
            }


            &.router-link-exact-active:hover,
            &.active:hover{
                background: var(--c-gray-2);
                color: var(--c-gray-5);
                cursor: default;
            }

            &.active:not(.router-link-exact-active):hover{
                cursor: pointer;
            }
        }

        > span {

        }
        .#{$prefix}side-nav-item__text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
        .#{$prefix}side-nav-item__inner{
            display: flex;
            width: 100%;
            gap: var(--margin-m);

            .#{$prefix}icon {
                position: relative;
                top: 0.1em;
            }

            .#{$prefix}badge {
                align-self: flex-end;
                margin-inline-start: auto;
                min-width: var(--margin-l);
            }
            .#{$prefix}badge.#{$prefix}side-nav-item__badge--icons {
                width:9px;
                height: 9px;
                min-width: 9px;
                border: 1px solid var(--c-bg);
                position: absolute;
                top: calc(var(--margin-m) * 1);
                inset-inline-start: calc(var(--margin-m) * 1);

            }

        }

        &.parent {
            .parent-deeper-icon {
                @include transition();
            }
            &:hover .parent-deeper-icon {
                transform: translateX(var(--margin-s));

                [dir=rtl] & {
                    transform: translateX(calc(-1 * var(--margin-s)));
                }
            }

        }
    }

    .#{$prefix}side-nav__back-link {
        cursor: pointer;
     //   padding-inline: calc(var(--margin-m));
        padding-block: calc(var(--margin-s));
        margin-bottom: 8px;
        border-radius: var(--border-radius-s);
        @include transition();
        &:hover {
            background: var(--c-gray-5);
            text-decoration: none;
            color: var(--c-bg);
        }
    }

    .#{$prefix}side-nav__back-icon {
        margin-inline-start: var(--margin-s);
    }

    .#{$prefix}side-nav-item + .#{$prefix}side-nav-item {
        .#{$prefix}side-nav-item__inner.#{$prefix}side-nav-item--title {
            margin-top: var(--margin-xl);
        }
    }

    .#{$prefix}side-nav-item--title{
        font-size: var(--font-s);
        padding-inline: var(--margin-m);
        margin-bottom: var(--margin-s);
        color: var(--c-gray-4);
        font-weight: 400;
    }

    .#{$prefix}side-nav-item.nav-item--mode-icon {
        &:first-of-type   .#{$prefix}side-nav-item--title {
            display: none;
        }
        .#{$prefix}side-nav-item--title {
            width: calc(var(--margin-xl));
            margin-inline: auto;
            height: 1px;
            background: var(--c-gray-2);
            margin-top: var(--margin-m)!important;
            margin-bottom: var(--margin-m)!important;
        }
    }


    //

}

