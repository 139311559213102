.#{$prefix}simple-value-list, .#{$prefix}svl {
  list-style: none;
    padding-inline-start: 0;

    li {
        display: flex;
        margin-bottom: var(--margin-s);
    }

    &__label {
        position: relative;
        display: flex;
        width: 120px;
        flex: 0 0 120px;
        margin-inline-end: var(--margin-l);
        align-items: flex-start;
        white-space: wrap;
        word-break: break-word;

        &::before {
            content: ' ';
            display: inline-block;
            height: var(--margin-m);
            width: var(--margin-m);
            flex: 0 0 var(--margin-m);
            margin-top: var(--margin-s);
            margin-inline-end: var(--margin-m);
            background-color: var(--c-gray-4);

        }

        @include whenSmallerThan(l) {
            width: 100px;
            flex: 0 0 150px;
            margin-inline-end: var(--margin-m);
        }
    }

    &__value {

    }
}


