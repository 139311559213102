//firefox
* {
    scrollbar-color: var(--c-lead-2) var(--c-gray) ;
    scrollbar-width: thin;
}

// chrome
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: var(--c-gray);
}

::-webkit-scrollbar-thumb {
    width: 4px;
    background-color: var(--c-lead-2);
    outline: none;
}
