$panelSelector : '.#{$prefix}panel';
$panelBorderRadius: var(--border-radius-s)!default;
#{$panelSelector} {
    background-color: var(--c-bg);
    border: 1px solid var(--c-gray-2);
    border-radius: $panelBorderRadius;
    padding: var(--margin-xl) var(--margin-l);


    &__title {
        margin-bottom: calc(var(--font-xl) * 1.4);
    }

    &--tight {
        padding: var(--margin-m) var(--margin-m);

        #{$panelSelector}__title {
            margin-bottom: calc(var(--font-xl) * 0.9);
        }
    }

    &--inverse {
        color: var(--c-bg);
        background-color: var(--c-inverse);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-lead-2);
        }
    }

    &--lead {
        color: var(--c-bg);
        background-color: var(--c-lead);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-bg);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--danger {
        color: var(--c-bg);
        background-color: var(--c-danger);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-bg);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--alt {
        color: var(--c-bg);
        background-color: var(--c-alt);
        border-color: var(--c-gray-1);

        a:not(button) {
            color: var(--c-bg);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--gray-1 {
        color: var(--c-inverse);
        background-color: var(--c-gray-1);
        border-color: var(--c-gray-2);

        a:not(button) {
            color: var(--c-lead);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &--gray {
        color: var(--c-bg);
        background-color: var(--c-gray);
        border-color: var(--c-gray-4);

        a:not(button) {
            color: var(--c-lead);
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    @if(mixin-exists(hook-panel)) {@include hook-panel();}
}
