$timelinePrefix : '.#{$prefix}timeline';
$timelineItemPrefix : '.#{$prefix}timeline-item';
:root {
    --timeline-inline-start-padding : 2em;
}

// todo:
// document the timeline& timelien items components
// implement a notificaiton component, with a timeline, regular and mini layouts
// abstrct the notification component
// add one more component for a classic use case
// test
// add notifications widget

// time - time ago format, add to i18n

#{$timelinePrefix} {
    padding-inline-start: calc(1 * var(--timeline-inline-start-padding));

    @if(mixin-exists(hook-timeline)) {@include hook-timeline();}

}
#{$timelineItemPrefix} {
    position: relative;
    padding-top: 0;
    padding-inline-start:  var(--margin-l);
    padding-bottom: var(--margin-max);
    padding-inline-end: 0;

    &:last-of-type {
        &::before {
            display: none;
        }
    }

    #{$timelineItemPrefix}__bullet {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        inset-inline-start: 0;
        transform: translateX(calc(-50% - 0.5 * var(--timeline-inline-start-padding)));
        min-height: 2em;
        min-width: 2em;
        border-radius: 50%;
        background-color: var(--c-gray);

        [dir=rtl] & {
            transform: translateX(calc(50% + 0.5 * var(--timeline-inline-start-padding)));
        }

        &--no-content {
           // margin-inline-start: calc( -1 * 1.5em);
            min-height: 1em;
            min-width: 1em;

            #{$timelineItemPrefix}__bullet-center {
                min-height: calc(1em - 4px);
                min-width: calc(1em - 4px);
                height: calc(100% - 4px);
                width: calc(100% - 4px);
                background-color: var(--c-bg);
                border-radius: 50%;
            }
        }

    }

    &::before{
        content: ' ';
        position: absolute;
        top: 1px;
        height: calc(100% - 1px);
        inset-inline-start: calc((-0.5 * var(--timeline-inline-start-padding)) - 1px);
        border-inline-start-style: solid;
        border-inline-start-width: 2px;
        border-inline-start-color: var(--c-gray);
    }

    &.active {
        &::before{
            border-inline-start-color: var(--c-lead);
        }

        #{$timelineItemPrefix}__bullet  {
            background-color: var(--c-lead);
        }

        #{$timelineItemPrefix}__bullet .#{$prefix}icon {
            color: var(--c-bg);
        }
    }

    &.line-dashed {
        &::before{
            border-inline-start-style: dashed;

        }
    }

    &.line-dotted {
        &::before{
            border-inline-start-style: dotted;

        }
    }

    &.line-none {
        &::before{
            border-inline-start-style: none;

        }
    }

    &__content {

    }

    &__title {
        font-size: var(--font-m);
        font-weight: 600;
        margin-bottom: 0;
    }

    &__timestamp {
       //c-gray-4 text-xs text-bold
        color: var(--c-gray-4);
        font-size: var(--font-xs);
        font-weight: bold;
        margin-bottom: var(--margin-m);
    }
    @if(mixin-exists(hook-timeline-item)) {@include hook-timeline-item();}


}
