
.#{$prefix}tabs {
    &__navigation {
        display: flex;
        padding-bottom: 5px;
        margin-bottom: var(--margin-l);
        overflow-y: hidden;
        overflow-x: auto;
    }

    &__navigation-inner {
        position: relative;
        display: flex;

        gap: var(--margin-xl);
        padding-inline: var(--margin-m);
        padding-bottom: var(--margin-s);
        z-index: var(--z-1);

        @include whenSmallerThan(s) {
            gap: var(--margin-m);
            padding-inline: var(--margin-s);
        }

        &::after {
            content: " ";
            position: absolute;
            bottom: 2px;
            inset-inline-start: 0;
            height: 2px;
            width: 100%;
            background-color: var(--c-gray-2);
            z-index: var(--z-1);
        }
    }

    &__control {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: var(--margin-m) var(--margin-s);
        cursor: pointer;
       // min-width: 120px;
        color: var(--c-gray);
        text-align: center;
        transition: 250ms color ease 250ms;
        z-index: var(--z-2);
        flex: auto;


        @include whenSmallerThan(s) {
            padding: var(--margin-s) var(--margin-s);
        }

        &::after {
            content: " ";
            position: absolute;
            bottom: -4px;
            width: calc(100% + 4px);
            background-color: var(--c-lead);
            height: 4px;
            inset-inline-start: -2px;
            max-width: 0;
            //opacity: 0;
            transition: 425ms ease;
            z-index: var(--z-2);

        }

        &.active {
            color: var(--c-lead);
            &::after {
                opacity: 1;
                max-width: 1200px;
            }
        }

        &.active:hover {
            color: var(--c-text);
            &::after {
                opacity: 1;
                max-width: 1200px;
            }
        }

        &:not(.active):hover {
            color: var(--gray-5);
            transition: 200ms ease;
            &::after {
                opacity: 1;
                max-width:  30%;
                transition: 200ms ease;
            }
        }
    }

    @if(mixin-exists(hook-tabs)) {@include hook-tabs();}
}

.#{$prefix}tab {

    min-height: 200px;
    @if(mixin-exists(hook-tab)) {@include hook-tab();}
}

