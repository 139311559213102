body .dp__main {
    .dp__input {
        line-height: 1;
    }
}
body .dp__menu, body .dp__main {

}

body .dp__menu {
    direction: ltr;
    text-align: left;
}

body .dp__overlay, body .dp__overlay_container {
    border-radius: var(--border-radius);
}
body .dp__menu, body .dp__theme_light {

    --dp-background-color: var(--c-bg);
    --dp-text-color: var(--c-inverse);
    --dp-hover-color: var(--c-bg-4);
    --dp-hover-text-color: var(--c-gray-5);
    --dp-hover-icon-color: var(--c-gray-5);
    --dp-primary-color: var(--c-alt);
    --dp-primary-disabled-color:var(--c-lead-2);
    --dp-primary-text-color:var(--c-bg-4);
    --dp-secondary-color: var(--c-gray-3);
    --dp-border-color: var(--c-gray-2);
    --dp-menu-border-color:  var(--c-gray-3);
    --dp-border-color-hover: var(--c-gray-4);
    --dp-disabled-color: var(--c-gray-2);
    --dp-scroll-bar-background: var(--c-gray-2);
    --dp-scroll-bar-color: var(--c-lead);
    --dp-success-color: var(--c-success);
    --dp-success-color-disabled: var(--c-success-2);
    --dp-icon-color: var(--c-gray-4);
    --dp-danger-color: var(--c-warning);
    --dp-marker-color: var(--c-warning);
    --dp-tooltip-color: var(--c-bg-4);
    --dp-disabled-color-text: var(--c-gray-4);
}

[dir=rtl] body {
    .dp__month_year_row {
        flex-direction: row-reverse;
    }

    .dp__calendar_header {
        flex-direction: row-reverse;
    }

    .dp__calendar .dp__calendar_row {
        flex-direction: row-reverse;
    }

}

body .dp__button.dp__button_bottom {
    height: auto;
    min-height: 35px; // originally was 35 pix height
    margin-bottom: 5px;

    svg.dp__icon  {
        height: 40px;
    }
}

body .dp__time_picker_inline_container {
    padding-bottom: var(--margin-m);
}

body .dp__marker_dot {
    background-color: var(--c-lead);
}

body[dir=rtl] .dp__tooltip_content {
    direction: rtl;
    text-align: right;
}
