// all elements with class like container or container-[something]
// and NOT list [something]-container (to prevent conflicts)
[class*=#{$prefix}container]:not([class*=#{$prefix}-container]) {
    flex: 100%;
    width: 100%;
    padding-inline-start: $container-gutter;
    padding-inline-end: $container-gutter;
    margin-inline-start: auto;
    margin-inline-end: auto;
    max-width: $container-l;

    &.container--expand {
        max-width: 100%;
    }

    & [class^=#{$prefix}container] {
        padding-inline-start: 0;
        padding-inline-end: 0;
    }
}

@each $key, $var in $container-map {
    .#{$prefix}container#{$key},
    .#{$prefix}container.#{$prefix}container#{$key}{
        flex: 100%;
        width: 100%;
        padding-inline-start: $container-gutter;
        padding-inline-end: $container-gutter;
        margin-inline-start: auto;
        margin-inline-end: auto;
        max-width: $var;
    }
}
