
$notificationSelector : '.#{$prefix}notification';
#{$notificationSelector} {
    --theme-color: var(--c-lead-2);
    --circle-countdown-size: 1.7em;
    --half-circle-countdown-size: 0.85em;
    --circle-countdown-width: 2px;
    --circle-countdown-color: var(--c-gray);

    display: block;

    min-height: 2.6em;
    width: clamp(300px, 100vw, 700px);
    max-width: calc(100vw - 2*var(--margin-m));
    margin: var(--margin-l) auto;
    border: 1px solid var(--c-gray-2);
    background-color: var(--c-bg-4);
    padding: var(--margin-s) var(--margin-m);
    padding-inline-start: var(--margin-m);
    padding-inline-end: calc (var(--margin-m)  + 2em);
    border-radius: var(--border-radius-sharp);
    box-shadow: var(--box-shadow-3);
    border-inline-start: 4px solid var(--theme-color);

    #{$notificationSelector}__close-icon-wrap {
        position: absolute;
        top: var(--margin-l);
        inset-inline-end: var(--margin-l);
        cursor: pointer;
        z-index:2;
        background: transparent;

        [dir=rtl] & {
            direction: ltr;
            text-align: left;
            inset-inline-end: auto;
            inset-inline-start: var(--margin-l);
        }

        #{$notificationSelector}__close-icon-wrap:hover {
            .left, .right {
                display: none;
            }
        }
        .left,
        .right {
            margin-top: calc(var(--half-circle-countdown-size) * -1);
            margin-left: calc(var(--half-circle-countdown-size) * -1);
            position: absolute;
            clip: rect(0, var(--circle-countdown-size), var(--circle-countdown-size), var(--half-circle-countdown-size));
        }

        .right {
            clip: rect(0px, var(--half-circle-countdown-size),var(--circle-countdown-size), 0px);
        }

        .left span,
        .right span {
            width: var(--circle-countdown-size);
            height: var(--circle-countdown-size);
            border-radius: 100%;
            position: absolute;
            opacity: 0.7;
            border: var(--circle-countdown-width) solid var(--circle-countdown-color);
        }

        .left span {
            clip: rect(0px, var(--half-circle-countdown-size), var(--circle-countdown-size), 0px);
           // -webkit-animation: circle-loader-rotate-left 5s infinite linear;
           // animation: circle-loader-rotate-left 5s infinite linear;
        }

        .right span {
            clip: rect(0, var(--circle-countdown-size), var(--circle-countdown-size),var(--half-circle-countdown-size));
         //   -webkit-animation: circle-loader-rotate-right 5s linear;
       //     animation: circle-loader-rotate-right 5s linear;
        }

        &:hover {
            #{$notificationSelector}__close-icon {
                transform: translateX(-50%) translateY(-50%) rotate(180deg);

            }
        }

        [dir=rtl] & #{$notificationSelector}__close-icon {
            direction: rtl;
            text-align: right;
            transform: translateX(-50%) translateY(-50%) rotate(180deg);

        }



        @-webkit-keyframes circle-loader-rotate-left {
            0% { -webkit-transform: rotate(0deg); }
            50% { -webkit-transform: rotate(180deg); }
            100% { -webkit-transform: rotate(180deg); }
        }
        @keyframes circle-loader-rotate-left {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(180deg); }
            100% { transform: rotate(180deg); }
        }
        @-webkit-keyframes circle-loader-rotate-right {
            0% { -webkit-transform: rotate(0deg); }
            50% { -webkit-transform: rotate(0deg); }
            100% { -webkit-transform: rotate(180deg); }
        }
        @keyframes circle-loader-rotate-right {
            0% { transform: rotate(0deg); }
            50% { transform: rotate(0deg); }
            100% { transform: rotate(180deg); }
        }
    }

    #{$notificationSelector}__close-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        cursor: pointer;
        border: none;
        z-index:2;

        @include transition(transform, 175ms, ease-in);

        &:hover {
            transform: translateX(-50%) translateY(-50%) rotate(180deg);
        }
    }

    &--closing {
        #{$notificationSelector}__close-icon, #{$notificationSelector}__close-icon:hover {
            transition: transform 300ms ease;
            transform: translateX(-50%) translateY(-50%) rotate(180deg) scale(0);
        }
    }

    &--first-of-type {
        margin-top: var(--margin-xl);
    }

    &--last-of-type {
        margin-bottom: 0;
    }

    &__inner {
        display: flex;
        gap: var(--margin-m);
        align-items: center;
        color: var(--c-lead-2);
    }

    &__icon-wrap {

    }

    &__text-wrap {
        display: flex;
        padding-inline-end: 2.75em;
        flex-direction: column;
    }

    &__title {
        color: var(--theme-color);
        font-size: var(--font-m);
        font-weight: 600;
        margin-bottom: var(--margin-xs);
    }

    &__content {
        color: var(--c-inverse);
    }

    $theme-map: (
            'danger'    : 'danger',
            'warning'   : 'warning',
            'success'   : 'success',
            'alt'       : 'alt',
            'comp-lead' : 'comp-lead',
            'comp-alt'  : 'comp-alt',
            'inverse'   : 'bg',
    );

    @each $key, $color in $theme-map {
        &--#{$key} {
            --theme-color: var(--c-#{$color});
        }
    }


    &__close-icon {
        color: var(--c-gray-4);
    }

    &--inverse {
        border-color: var(--c-gray);
        background: var(--c-inverse);

        #{$notificationSelector}__close-icon {
            color: var(--c-bg-4);
        }

        #{$notificationSelector}__content {
            color: var(--c-bg-4);
        }


    }

    &--inverse {

    }

    @if(mixin-exists(hook-notification)) {@include hook-notification();}
}
